@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&display=swap");

* {
    overflow-x: hidden;
    font-family: "Heebo", "sans-serif";
    font-weight: 300;
}

.main-window {
    height: 410vh;
}

.loader-container {
    z-index: 50;
    background: rgba(108, 106, 106, 0.59);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.8px);
    -webkit-backdrop-filter: blur(7.8px);
}

.loader {
    margin: 0 0 2em;
    height: 100px;
    width: 20%;
    text-align: center;
    padding: 1em;
    margin: 0 auto 1em;
    display: inline-block;
    vertical-align: top;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1200px) {
    .main-window {
        height: 400vh;
    }
}

@media screen and (max-width: 1024px) {
    .main-window {
        height: 500vh;
    }
}

@media screen and (max-width: 768px) {
    .main-window {
        height: 560vh;
    }
}

@media screen and (max-width: 640px) {
    .main-window {
        height: 730vh;
    }
}
