.photo {
    background-image: url("../../assets/omri1.jpg");
    background-size: cover;
    border-radius: 25px;
}

.bottom {
    background-image: url("../../assets/omri2.jpg");
    background-size: cover;
    border-radius: 25px;
    width: 15%;
    height: 5%;
    min-height: 250px;
    position: absolute;
    z-index: 10;
    min-width: 100px;
    max-width: 150px;
}

.top {
    background-image: url("../../assets/omri3.png");
    background-size: cover;
    border-radius: 25px;
    width: 15%;
    min-width: 300px;
    height: 5%;
    position: absolute;
    z-index: 20;
    min-height: 400px;
}

@media screen and (max-width: 768px) {
    .bottom {
        width: 35%;
        height: 100px;
    }
}
