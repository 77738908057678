.container {
    z-index: -1;
    height: 120vh;
    min-height: 300px;
    width: 100vw;
    overflow-y: hidden;
}

.container * {
    overflow-y: hidden;
}

@media and screen (max-width: 640px) {
    .container {
        height: 80vh;
    }
}
