.container * {
    overflow: hidden;
}

.render {
    z-index: 20;
    visibility: hidden;
    opacity: 0;
    transition: all 0.6s ease;
}

.show {
    visibility: visible;
    opacity: 1;
}
