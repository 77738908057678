.container {
    position: absolute;
    top: 73px;
}

.container * {
    overflow: hidden;
}

@media screen and (max-width: 1040px) {
    .container {
        top: 65px;
    }
}
