.container {
    background-image: url("../../assets/about.jpg");
    background-size: cover;
}

.container * {
    overflow-y: hid;
}

.photo {
    background-image: url("../../assets/omri-small1.jpg");
    background-size: cover;
    border-radius: 25px;
}
