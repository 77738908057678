.container {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
}

.container * {
    overflow-y: hidden;
}

.render {
    visibility: hidden;
    opacity: 0;
    transition: all 0.6s ease;
}

.show {
    visibility: visible;
    opacity: 1;
}

.select {
    text-align-last: right;
}

.select option {
    direction: rtl;
}
