.container {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
}

.render {
    visibility: hidden;
    opacity: 0;
    transition: all 0.6s ease;
}

.show {
    visibility: visible;
    opacity: 1;
}
